
import { computed, defineComponent, PropType } from 'vue'

import iClose from '@/assets/icons/Close.svg'
import iClip from '@/assets/icons/Clip.svg'

export default defineComponent({
    components: {
        'icon-close': iClose,
        'icon-clip': iClip,
    },
    props: {
        value: {
            type: Array as PropType<File[]>,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: null,
        },
        accept: {
            type: String,
            default: 'image/*',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const computedPlaceholder = computed(() => {
            if (props.value.length) {
                if (props.value.length > 1) {
                    return `Прикреплено: ${props.value.length}`
                }

                return props.value[0].name
            }

            return props.placeholder
        })

        const emitUpdate = (event: InputEvent) => {
            const target = event.target as HTMLInputElement
            const files = target.files

            const array_of_files: File[] = []

            if (!files || !files.length) {
                emit('update:value', array_of_files)
                return
            }

            for (let index = 0; index < files.length; index++) {
                const file = files.item(index)
                if (!file) continue
                array_of_files.push(file)
            }

            if (props.multiple) {
                const value_files = [...props.value]
                emit('update:value', value_files.concat(array_of_files))
            } else {
                emit('update:value', array_of_files)
            }

            target.value = ''
        }

        const resetFiles = () => {
            emit('update:value', [])
        }

        const removeFile = (file_index: number) => {
            const files = [...props.value]
            files.splice(file_index, 1)

            emit('update:value', files)
        }

        return { computedPlaceholder, emitUpdate, resetFiles, removeFile }
    },
})
