
import { computed, defineComponent, onBeforeMount } from 'vue'

// Components
import SInput from '@/common/components/SInput/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import STextArea from '@/common/components/STextArea/index.vue'
import SFile from '@/common/components/SFile/index.vue'

// Composable
import usePretension from '@/modules/PretensionsModule/composable/usePretension'

import { useDebounce } from 'vue-composable'

export default defineComponent({
    components: {
        's-input': SInput,
        's-loader': SLoader,
        's-button': SButton,
        's-textarea': STextArea,
        's-file': SFile,
    },
    props: {
        isUpdate: {
            type: Boolean,
            default: false,
        },
        isCreate: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const {
            info_state,
            pretension_state,
            loadInformationByInvoiceNumber,
            resetInformation,
            form_state,
            form_state$,
            loadTypes,
            data_state,
            onSubmit,
            loadPretension,
            getFileTypeByUrl,
            downloadFile,
            deleteFile,
            uploadFiles,
        } = usePretension({ is_create: props.isCreate, is_update: props.isUpdate })

        const onInvoiceNumberInput = useDebounce((event: Event) => {
            const target = event.target as HTMLInputElement
            const value = target.value

            if (!value) {
                resetInformation()
                return
            }

            loadInformationByInvoiceNumber(value)
        }, 600)

        onBeforeMount(() => {
            loadTypes()

            if (props.isUpdate) {
                loadPretension()
            }
        })

        const isOtherType = computed(() => {
            return form_state.type_id === 5
        })

        return {
            info_state,
            pretension_state,
            onInvoiceNumberInput,
            form_state,
            form_state$,
            onSubmit,
            data_state,
            getFileTypeByUrl,
            downloadFile,
            deleteFile,
            uploadFiles,
            isOtherType,
        }
    },
})
