
import { defineComponent } from 'vue'

import PretensionForm from '@/modules/PretensionsModule/components/PretensionForm/index.vue'
import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        'pretension-form': PretensionForm,
    },
    setup() {
        useTitle('Создание претензии')
    },
})
